import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { useMemo } from "preact/hooks";
import { formatAddressToString } from "./formatting";
function AddressDetails({ address, onEdit }) {
    const addressString = useMemo(() => formatAddressToString(address), [address]);
    const editTriggered = () => {
        onEdit();
    };
    if (!addressString) {
        return null;
    }
    return (_jsxs("div", { className: "address__details", children: [_jsx("div", { className: "form__control", children: addressString }), _jsx("button", { type: "button", onClick: editTriggered, className: "heading-5 btn btn--edit-address", children: "Edit address" })] }));
}
export default AddressDetails;
